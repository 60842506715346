import { Store } from '@/shared/types/store.interface';

export enum InventoryPolicy {
  ALLOW = 'allow',
  CONTINUE = 'continue',
  DENY = 'deny',
}

export interface IProductVariantSelectedOption {
  name: string;
  value: string;
}

export interface IProductVariant {
  checkedAt: string;
  createdAt: string;
  deltaQuantity: number;
  id: number;
  inventoryItemId: number;
  inventoryManagement: string;
  inventoryPolicy: string;
  inventoryQuantity: number;
  isLost: false;
  jewelryId: number;
  legacySku: string;
  locationId: number;
  productId: number;
  quantityChangedAt: string;
  selectedOptions: IProductVariantSelectedOption[];
  sku: string;
  updatedAt: string;
  variantId: number;
}

export interface IProductBasic {
  categories: string[];
  coverUrls: string[];
  gemstones: string[];
  id: number;
  isEnabled: boolean;
  materials: string[];
  name: string;
  store: Store;
  storeId: number;
  syncData: any;
}

export interface IProduct extends IProductBasic {
  betaSort: number;
  componentIds: number[];
  createdAt: string;
  deletedAt: string;
  descriptionOverride: string;
  digitalProduct: boolean;
  exchange: string;
  extId: number;
  globalSort: number;
  isBelongToPlatform: boolean;
  isFromPrintful: boolean;
  isPreSale: boolean;
  lastPushPlatformAt: string;
  meta: any;
  origin: string;
  skus: IProductVariant[];
  sortWeight: number;
  syncAt: string;
  syncId: string;
  tags: string[];
  twinId: number;
  updatedAt: string;
}
export interface IVariantDictionary {
  [key: string]: {
    id: number;
    price: number;
    title: string;
    availableForSale: boolean;
    image: {
      originalSrc: string;
      altText: string;
    };
    inventory_policy: InventoryPolicy;
  };
}

export interface GetProductCheckoutUrlParams {
  anonymousUserId: string;
  items: {
    jewelryId: number;
    variantId: string;
    quantity: number;
  }[];
}
