import { isEmpty } from 'lodash';
import {
  HeaderTag,
  jsonLdParam,
  pageMetadataArg,
  SeoMeta,
  SEO_META_PAGES,
  SeoHeader,
  MetaInfo,
} from '@/shared/types/seo';
import {
  defaultPietraJsonLd,
  marketplaceJsonLd,
  creatorsListJsonLd,
  productsListJsonLd,
  getProductJsonLd,
  plansJsonLd,
  registerJsonLd,
  storeJsonLd,
} from './jsonld';
import { PIETRA_LOGO_SQUARE } from '@/shared/constants/app';

export const SEO_META: SeoMeta = {
  [SEO_META_PAGES.BRAND_KIT]: {
    headers: (headerData: SeoHeader[]) => headerData,
    head: headMetadata,
    jsonld: ({ store, url }: jsonLdParam) => [marketplaceJsonLd(url), storeJsonLd(store, url)],
  },
  [SEO_META_PAGES.CATALOG_ITEMS]: {
    headers: (headerData: SeoHeader[]) => headerData,
    head: headMetadata,
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.EXISTING_BRANDS]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Pietra gives you the tools to streamline your operations.' },
      { tag: HeaderTag.H2, text: 'Supercharge your eCommerce & Grow Your Sales' },
      { tag: HeaderTag.H2, text: 'Expand Your Product Offering Without Expanding Your Team.' },
      { tag: HeaderTag.H2, text: '$1.15 Order Fulfillment & 2-Day Shipping For All.' },
      { tag: HeaderTag.H2, text: 'Feel supported & learn how to create a successful business.' },
    ],
    head: headMetadata,
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.NEW_BRANDS]: {
    headers: [
      {
        tag: HeaderTag.H1,
        text: 'The Fastest, Easiest, And Most Affordable Way To Start Your Brand.',
      },
      { tag: HeaderTag.H2, text: 'Source Products & Packaging At Steep Discounts.' },
      { tag: HeaderTag.H2, text: '$1.15 Order Fulfillment & 2-Day Shipping For All.' },
      { tag: HeaderTag.H2, text: 'Supercharge Your eCommerce & Grow Your Sales' },
      { tag: HeaderTag.H2, text: 'Feel supported & learn how to create a successful business.' },
    ],
    head: headMetadata,
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.ERROR]: {
    headers: [{ tag: HeaderTag.H1, text: "Sorry, we couldn't find the page you're looking for." }],
    head: headMetadata,
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.HOME]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Pietra is everything you need to start and scale your brand.' },
      { tag: HeaderTag.H2, text: 'Source Products' },
      { tag: HeaderTag.H2, text: 'Start Selling' },
      { tag: HeaderTag.H2, text: 'Fulfill Orders' },
    ],
    head: headMetadata,
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.HOW_PIETRA_WORKS]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Sourcing, Fulfillment Services, and eCommerce under one roof.' },
      { tag: HeaderTag.H2, text: 'Source Products & Packaging At Steep Discounts.' },
      { tag: HeaderTag.H2, text: '$1.15 Order Fulfillment & 2-Day Shipping For All.' },
      { tag: HeaderTag.H2, text: 'Supercharge your eCommerce & Grow Your Sales' },
      { tag: HeaderTag.H2, text: 'Feel supported & learn how to create a successful business.' },
    ],
    head: headMetadata,
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.MARKETPLACE]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Pietra is best place to launch and grow a Shopify brand.' },
      { tag: HeaderTag.H2, text: 'Discover products created by entrepreneurs.' },
      {
        tag: HeaderTag.H3,
        text: 'The Pietra marketplace is a community built on helping brands tell their stories in the way they want to tell them.',
      },
    ],
    head: (params: pageMetadataArg) => headMetadata(params),
    jsonld: ({ stores, products, url }: jsonLdParam) => [
      marketplaceJsonLd(`${url}/marketplace`),
      creatorsListJsonLd(stores, `${url}/marketplace`, true),
      productsListJsonLd(products, `${url}/marketplace`, 'Popular Products'),
    ],
  },
  [SEO_META_PAGES.MARKETPLACE_SHOP_ALL]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Shop from the largest marketplace of e-ecommerce brands.' },
      { tag: HeaderTag.H2, text: 'Check out our most popular products.' },
    ],
    head: headMetadata,
    jsonld: ({ products, url }: jsonLdParam) => [
      marketplaceJsonLd(`${url}/shop/all`),
      productsListJsonLd(products, `${url}/shop/all`, 'Creator Products'),
    ],
  },
  [SEO_META_PAGES.MARKETPLACE_CREATOR_SHOP]: {
    headers: (headerData: SeoHeader[]) => headerData,
    head: headMetadata,
    jsonld: ({ store, url }: jsonLdParam) => [marketplaceJsonLd(url), storeJsonLd(store, url)],
  },
  [SEO_META_PAGES.AUTOMATED_RETURNS]: {
    headers: (headerData: SeoHeader[]) => headerData,
    head: headMetadata,
    jsonld: ({ store, url }: jsonLdParam) => [marketplaceJsonLd(url), storeJsonLd(store, url)],
  },
  [SEO_META_PAGES.MARKETPLACE_PDP]: {
    headers: (headerData: SeoHeader[]) => headerData,
    head: headMetadata,
    jsonld: ({ product, url }: jsonLdParam) => getProductJsonLd(product, url),
  },
  [SEO_META_PAGES.PIETRA_PARTNERS]: {
    headers: [
      {
        tag: HeaderTag.H1,
        text: 'List Your Products & Services And Get Free Leads',
      },
      { tag: HeaderTag.H2, text: 'Do less work. Get more customers.' },
      { tag: HeaderTag.H3, text: 'Set up your profile' },
      { tag: HeaderTag.H3, text: 'List your private label products' },
      { tag: HeaderTag.H3, text: 'Accept creator manufacturing requests' },
      { tag: HeaderTag.H3, text: 'Connect With Creators In One Centralized Portal' },
    ],
    head: headMetadata,
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.PRICING]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Pietra Pricing - Setup and Open Your Online Store Today' },
      { tag: HeaderTag.H2, text: 'Pietra has a plan for every creator.' },
      { tag: HeaderTag.H3, text: 'Pietra - The smartest way to start your eCommerce business.' },
      {
        tag: HeaderTag.H3,
        text: 'Pietra With Priority Support - The smartest way to start and scale your eCommerce business.',
      },
      { tag: HeaderTag.H3, text: 'Pietra Premium - For scaling brands shipping 2000+ units/mo' },
    ],
    head: headMetadata,
    jsonld: ({ plans, url }: jsonLdParam) => [defaultPietraJsonLd(), plansJsonLd(url, plans)],
  },
  [SEO_META_PAGES.SELL_ON_PIETRA]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Start your eCommerce business with Pietra' },
      { tag: HeaderTag.H2, text: 'Source Products' },
      { tag: HeaderTag.H2, text: 'Start Selling' },
      { tag: HeaderTag.H2, text: 'Fulfill Orders' },
    ],
    head: headMetadata,
    jsonld: () => [defaultPietraJsonLd(), registerJsonLd()],
  },
  [SEO_META_PAGES.SETUP_COMMERCE]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Sell Your Products Online With Pietra Commerce' },
      { tag: HeaderTag.H2, text: 'Order fulfillment & 2-day shipping' },
      { tag: HeaderTag.H2, text: 'Kitting & Assembly Services' },
      { tag: HeaderTag.H2, text: 'eCommerce Photography' },
      { tag: HeaderTag.H2, text: 'Inventory Storage' },
    ],
    head: headMetadata,
    jsonld: () => [defaultPietraJsonLd(), registerJsonLd()],
  },
  [SEO_META_PAGES.MARKETPLACE_DESIGNERS]: {
    headers: [
      { tag: HeaderTag.H1, text: 'Browse creator-led brands on Pietra' },
      { tag: HeaderTag.H2, text: 'Find your favorite creator-led brands on Pietra' },
    ],
    head: headMetadata,
    jsonld: ({ stores, url }: jsonLdParam) => [
      defaultPietraJsonLd(),
      creatorsListJsonLd(stores, url),
    ],
  },
  [SEO_META_PAGES.FULFILLMENT_SERVICES]: {
    headers: [], // already in template
    head: headMetadata,
    jsonld: () => defaultPietraJsonLd(),
  },
};

function headMetadata(meta: pageMetadataArg): MetaInfo {
  return {
    title: meta.title,
    meta: setPageMetadata(meta),
    script: meta.script || [],
    link: [
      {
        rel: 'canonical',
        href: meta.url,
      },
    ],
  };
}

function getDefaultMetadata() {
  return {
    title: 'Pietra',
    url: 'https://pietra.store',
    description: 'Launch Your Creative Business | Pietra',
    image: PIETRA_LOGO_SQUARE,
  };
}

export function setPageMetadata(metadata: pageMetadataArg): any {
  const defaults = getDefaultMetadata();

  return [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, user-scalable=0, user-scalable=yes',
    },
    {
      hid: 'title',
      name: 'title',
      content: !isEmpty(metadata.title) ? metadata.title : defaults.title,
    },
    {
      hid: 'description',
      name: 'description',
      content: !isEmpty(metadata.description) ? metadata.description : defaults.description,
    },
    {
      hid: 'og:title',
      name: 'og:title',
      property: 'og:title',
      content: !isEmpty(metadata.title) ? metadata.title : defaults.title,
    },
    {
      hid: 'og:description',
      name: 'og:description',
      property: 'og:description',
      content: !isEmpty(metadata.description) ? metadata.description : defaults.description,
    },
    {
      hid: 'og:type',
      name: 'og:type',
      property: 'og:type',
      content: 'website',
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      property: 'og:site_name',
      content: !isEmpty(metadata.siteName) ? metadata.siteName : defaults.title,
    },
    {
      hid: 'og:image',
      name: 'og:image',
      property: 'og:image',
      content: !isEmpty(metadata.image) ? metadata.image : defaults.image,
    },
    {
      hid: 'og:url',
      name: 'og:url',
      property: 'og:url',
      content: !isEmpty(metadata.url) ? metadata.url : defaults.url,
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: !isEmpty(metadata.title) ? metadata.title : defaults.title,
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: !isEmpty(metadata.description) ? metadata.description : defaults.description,
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: !isEmpty(metadata.image) ? metadata.image : defaults.image,
    },
    {
      hid: 'twitter:image:alt',
      name: 'twitter:image:alt',
      content: defaults.description,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:site',
      content: '@shoppietra',
    },
    {
      name: 'twitter:creator',
      content: '@shoppietra',
    },
  ];
}
