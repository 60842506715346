import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { SchemaLink } from '@apollo/client/link/schema';
import { getAffiliateTokenKey } from '@/shared/constants/app';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import { setContext } from '@apollo/client/link/context';
import storage from '@/shared/utils/storage';

const { publicRuntimeConfig } = getConfig();
let apolloClient: ApolloClient<any>;

function createIsomorphLink() {
  if (typeof window === 'undefined') {
    return new SchemaLink(<SchemaLink.Options>{ schema: {} });
  } else {
    return new HttpLink({
      uri: publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_API_HOST + '/graphql',
    });
  }
}

function createApolloClient({ handle }: { handle?: string }) {
  const errorLink = onError(({ networkError, response }) => {
    let _message: string;
    if (networkError) {
      _message = 'network request failed';
    } else {
      _message = response?.errors?.[0]?.message;
    }
    const statusCode = response?.errors?.[0]?.extensions?.['statusCode'];
    message.error(_message);
    if (statusCode && (statusCode === 401 || statusCode === 403 || statusCode === 988)) {
      // There is no token, or the token expires, what 401 403, 988. Jump to the dedicated page.
      message.error('Failed to obtain token, please log in again');
    }
  });
  const authLink = setContext((_, { headers }) => {
    const token = storage.get(getAffiliateTokenKey(handle));
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    };
  });
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([concat(authLink.concat(errorLink), createIsomorphLink())]),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState: { handle?: string; [key: string]: any } = null) {
  const _apolloClient = apolloClient ?? createApolloClient({ handle: initialState?.handle ?? '' });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = { ...initialState, ...existingCache };

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: { handle?: string; [key: string]: any }) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
