import { Tag } from '@/shared/types/marketplace.interface';

export const BEGINNER_PLATFORM_MONTHLY_COST = 49;
export const INTERMEDIATE_PLATFORM_MONTHLY_COST = 89;
export const PREMIUM_PLATFORM_MONTHLY_COST = 199;
export const TAGS: Tag[] = [
  { value: 'Sustainable', color: '#D7FADB' },
  { value: 'Female Founded', color: '#ECECFD' },
  { value: 'Expert Craftsmanship', color: '#FFAEA5' },
  { value: 'All-Natural', color: '#DDEEFD' },
  { value: 'Black Owned', color: '#FFEFEC' },
  { value: 'Genderless', color: '#FFD8BE' },
  { value: 'International', color: '#ECEAE4' },
  { value: 'US-Based', color: '#B8D6E7' },
  { value: 'Educational', color: '#FFDFCD' },
  { value: 'Plant-Based', color: '#BED9B7' },
  { value: 'Vegan', color: '#63BEA0' },
  { value: 'Toxin Free', color: '#6DC1C5' },
  { value: 'Organic', color: '#DDEEFD' },
  { value: 'Sexual Health', color: '#BAF7FF' },
];

export const FB_ADVERTISER_ID = '489858031489875';

// storage keys

export const ANONYMOUS_USER_ID = 'anonymousUserId';
export const CAMPAIGN_DATA = 'campaignData';

// pattern

export const PATTERN_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PIETRA_LOGO_STONE =
  'https://static.pietrastudio.com/public/file_uploads/fc776bfe5404d3fda10edf6e6cfa8c07.png';

export const PIETRA_LOGO_SQUARE =
  'https://static.pietrastudio.com/public_large/file_uploads/a280b304cc0c8d92471a07029a934ba0.png';

export const PIETRA_PREVIEW_ACCOUNT_USERNAME = 'previewpietra@pietrastudio.com';
export const PIETRA_PREVIEW_ACCOUNT_PASSWORD = 'previewpietra';

export const SORT_OPTIONS: Array<{ label: string; value: string }> = [
  {
    label: 'Popularity',
    value: '-globalSort',
  },
  {
    label: 'Price Low to High',
    value: '+minPrice',
  },
  {
    label: 'Price High to Low',
    value: '-minPrice',
  },
  {
    label: 'Newly Added',
    value: '-createdAt',
  },
];

export const PRICE_OPTIONS: { [key: string]: { label: string; value: string } } = {
  '[0,25]': {
    label: 'Under $25',
    value: '[0,25]',
  },
  '[25,50]': {
    label: '$25 - $50',
    value: '[25,50]',
  },
  '[50,100]': {
    label: '$50 - $100',
    value: '[50,100]',
  },
  '[100,200]': {
    label: '$100 - $200',
    value: '[100,200]',
  },
  '[200,300]': {
    label: '$200 - $300',
    value: '[200,300]',
  },
  '[300,3000]': {
    label: '$300 - $3,000',
    value: '[300,3000]',
  },
};

export const MIN_QUANTITY_DISPLAY = 1;
export const MAX_QUANTITY_DISPLAY = 25;
export const CHARCODE_ENTER = 13;

export const getAffiliateTokenKey = (handle: string) => `affiliate-${handle.toLowerCase()}-token`;

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const SPARK = '#FF5C3C';
