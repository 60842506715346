import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/fonts.css';
import 'nprogress/nprogress.css';
import '@/styles/globals.less';
import { ConfigProvider as AntdConfigProvider, message, App as AntdApp } from 'antd';
import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConfigProvider, ShoppingCartProvider } from '@/lib/context';
import NotFoundPage from '@/pages/404';
import packageInfo from '../../package.json';
import Script from 'next/script';
import { useServerInsertedHTML } from 'next/navigation';
import storage from '@/shared/utils/storage';
import { ANONYMOUS_USER_ID, CAMPAIGN_DATA } from '@/shared/constants/app';
import { generateUniqueSessionId } from '@/shared/utils/utils';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/lib/hooks/utils/createGraphQLClient';

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);
message.config({
  top: 60,
  prefixCls: 'pietra-message',
});

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

if (process.env.NEXT_PUBLIC_ENV === 'staging' || process.env.NEXT_PUBLIC_ENV === 'production') {
  Sentry.init({
    dsn: 'https://81cc8b0a4eb545449bedad45af7c3496@o907233.ingest.sentry.io/4504489964732416',
    release: 'marketplace@' + packageInfo.version,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NEXT_PUBLIC_ENV,
    tracesSampleRate: 0.5,
    sampleRate: 0.5,
  });
}
if (typeof window === 'object') {
  if (storage.get(ANONYMOUS_USER_ID) === undefined) {
    storage.set(ANONYMOUS_USER_ID, generateUniqueSessionId());
  }
}
export default function App({ Component, pageProps }: AppPropsWithLayout<{ userAgent?: string }>) {
  const router = useRouter();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const apolloClient = useApollo({ handle: String(router.query.handle) });

  // SSR Render
  const [cache] = useState(() => createCache());

  useServerInsertedHTML(() => {
    return (
      <Script
        id="cache-styles"
        dangerouslySetInnerHTML={{
          __html: `<Script>${extractStyle(cache)}</Script>`,
        }}
      />
    );
  });

  useEffect(() => {
    const query = router.query;
    if (
      query &&
      (query.hasOwnProperty('utm_campaign') ||
        query.hasOwnProperty('utm_medium') ||
        query.hasOwnProperty('utm_source') ||
        query.hasOwnProperty('utm_content'))
    ) {
      storage.set(CAMPAIGN_DATA, {
        campaign: query['utm_campaign'] || '',
        campaignSource: query['utm_source'] || '',
        campaignMedium: query['utm_medium'] || '',
        campaignContent: query['utm_content'] || '',
      });
    }
  }, [router.query]);

  return (
    <AntdConfigProvider
      prefixCls="pietra"
      theme={{
        token: {
          colorPrimary: '#f9e070',
          colorError: '#de0000',
          borderRadius: 6,
          wireframe: false,
          fontFamily: 'Labil-Regular',
        },
        components: {
          Select: {
            borderRadius: 0,
            borderRadiusLG: 0,
            colorBgContainer: '#f2f2ed',
            colorBorder: '#f2f2ed',
            colorPrimaryHover: '#f2f2ed',
          },
          Pagination: {
            fontFamily: 'Attila-Semibold',
            fontSize: 18,
            lineWidth: 0,
            colorBorder: 'transparent',
            colorText: '#141414',
            colorPrimary: '#141414',
            colorPrimaryHover: '#141414',
          },
          Message: {
            colorBgElevated: '#141414',
            borderRadius: 0,
            borderRadiusLG: 0,
            lineHeight: 2.5,
          },
        },
      }}
    >
      <Sentry.ErrorBoundary fallback={NotFoundPage}>
        <StyleProvider cache={cache}>
          <ConfigProvider value={{ userAgent: pageProps.userAgent ?? '' }}>
            <ApolloProvider client={apolloClient}>
              <ShoppingCartProvider>
                <Script
                  id="google-tag-manager"
                  strategy="lazyOnload"
                  src={'https://www.googletagmanager.com/gtag/js?id=G-47PJTWDLGL'}
                />
                <Script
                  id="google-dataLayer"
                  strategy="lazyOnload"
                  dangerouslySetInnerHTML={{
                    __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-47PJTWDLGL');
                `,
                  }}
                ></Script>
                <AntdApp>{getLayout(<Component {...pageProps} />)}</AntdApp>
              </ShoppingCartProvider>
            </ApolloProvider>
          </ConfigProvider>
        </StyleProvider>
      </Sentry.ErrorBoundary>
    </AntdConfigProvider>
  );
}
