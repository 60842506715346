import React from 'react';
import Error from '@/components/Error';
import Layout from '@/layouts';
import { SEO_META } from '@/shared/seo/seo';
import { MetaInfo, SEO_META_PAGES } from '@/shared/types/seo';

const Custom404 = () => {
  const head = SEO_META[SEO_META_PAGES.ERROR].head({
    title: "We Couldn't Find What You're Looking For | Pietra",
    description:
      'Please make sure you typed in the right URL. You can contact Pietra to discuss any problems you are experiencing.',
    url: typeof window === 'object' ? window.location.href : '',
  });
  return (
    <Layout
      headers={SEO_META[SEO_META_PAGES.ERROR].headers}
      structured={
        SEO_META[SEO_META_PAGES.ERROR].jsonld({
          url: typeof window === 'object' ? window.location.href : '',
        }) as any
      }
      head={head as MetaInfo}
    >
      <Error statusCode={400} />
    </Layout>
  );
};

export default Custom404;
