import React, { createElement, useMemo } from 'react';
import GlobalHeadMeta from '@/components/Layouts/GlobalHeadMeta';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import PasswordProtection from '@/components/Common/PasswordProtection';
import { Store } from '@/shared/types/store.interface';
import { MetaInfo, SeoHeader } from '@/shared/types/seo';
import { useConfigProvider } from '@/lib/context/ConfigProvider';
import { useRouter } from 'next/router';
import { useToggle } from 'react-use';
import { isStorePasswordProtected } from '@/shared/utils/store-helper';
import './index.less';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: MetaInfo;
  headers?: SeoHeader[];
  structured?: { [key: string]: string };
  children: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  store?: Store;
}

const BrandedHeader = dynamic(() => import('@/components/Layouts/BrandedHeader'), {});
const GlobalHeader = dynamic(() => import('@/components/Layouts/GlobalHeader'), {});
const GlobalFooter = dynamic(() => import('@/components/Layouts/GlobalFooter'), {});
const SellOnPietraDialog = dynamic(() => import('@/components/Layouts/SellOnPietraDialog'), {
  ssr: false, // As we do not need this component on the server, we have used ssr: false to disable it.
});

const Layout = ({
  children,
  head,
  headers,
  structured,
  hideHeader = false,
  hideFooter = false,
  store,
}: LayoutProps) => {
  const { pathname } = useRouter();
  const { menus, isMobile, isShopPage, unlockedStores } = useConfigProvider();
  const [open, toggle] = useToggle(false);

  const isPasswordProtected = useMemo(
    () => (store ? isStorePasswordProtected(store) : false),
    [store],
  );
  const showPasswordScreen = useMemo(
    () => isPasswordProtected && !unlockedStores.includes(store?.id),
    [isPasswordProtected, store?.id, unlockedStores],
  );

  const navItems = [
    {
      text: 'Shop All',
      path: '/shop/all',
    },
    {
      text: 'Discover',
      dropdownItems: menus || [],
    },
    {
      text: 'Sell on Pietra',
      onClick: () => {
        toggle();
      },
    },
  ];

  const passwordScreen = (
    <div className="password-protected-page">
      <PasswordProtection store={store} />
    </div>
  );

  const renderHeaders = useMemo(() => {
    return (headers || []).map((header, index) =>
      createElement(header.tag, { key: `header-item-${index}` }, header.text),
    );
  }, [headers]);

  return (
    <div className="layout">
      <GlobalHeadMeta head={head} structured={structured} />

      <header
        className={classNames('header', {
          hide: hideHeader,
        })}
      >
        {store && isPasswordProtected ? (
          <BrandedHeader store={store} className={`${isMobile ? 'mobile' : ''}`} />
        ) : (
          <GlobalHeader
            isMobile={isMobile}
            isMarketplaceHome={pathname === '/'}
            isShopPage={isShopPage}
            navItems={navItems}
          />
        )}

        {open && <SellOnPietraDialog isMobile={isMobile} isModalOpen={open} onCancel={toggle} />}
      </header>

      <main
        className={classNames('main', {
          mobile: isMobile,
          'hide-header': hideHeader,
        })}
      >
        <div style={{ display: 'none' }}>{renderHeaders}</div>
        {showPasswordScreen ? passwordScreen : children}
      </main>

      <footer
        className={classNames('footer', {
          hide: hideFooter,
        })}
      >
        <GlobalFooter
          className={classNames({ protected: isPasswordProtected })}
          isPasswordProtected={isPasswordProtected}
        />
      </footer>
    </div>
  );
};
export default Layout;
