import React, { useMemo } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { MetaInfo } from '@/shared/types/seo';

interface GlobalHeadMetaProps {
  head?: MetaInfo;
  structured?: { [key: string]: string };
}

const GlobalHeadMeta = ({ head, structured }: GlobalHeadMetaProps) => {
  const { pathname, asPath } = useRouter();
  return (
    <>
      {useMemo(() => {
        if (head) {
          let childrens: Array<React.ReactElement> = [];
          for (const key in head) {
            switch (key) {
              case 'title':
                childrens.push(<title>{head[key]}</title>);
                break;
              case 'meta':
                head[key].forEach((meta) => {
                  childrens.push(<meta key={meta.name} {...meta} />);
                });
                break;
              case 'link':
                head[key].forEach((link, index) => {
                  childrens.push(<link key={`link-item-${index}`} {...(link as any)} />);
                });
                break;
              case 'script':
                head[key].forEach((script, index) => {
                  childrens.push(<Script key={`script-item-${index}`} {...(script as any)} />);
                });
                break;
            }
          }
          return (
            <Head>
              <meta name="mobile-web-app-capable" content="yes" />
              <meta name="apple-mobile-web-app-title" content="pietra-website" />
              <meta
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                name="viewport"
              />
              {...childrens}
            </Head>
          );
        }

        return (
          <Head>
            <title>The Largest Creator-First Marketplace | Pietra</title>
            <meta
              name="description"
              content="Shop the largest marketplace in the world for creator-branded goods."
            />
            <meta
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
              name="viewport"
            />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-title" content="pietra-website" />
            <meta name="title" content="The Largest Creator-First Marketplace | Pietra" />
            <link rel="icon" href="/favicon.ico" />
            <link rel="canonical" href={asPath ?? pathname} />
          </Head>
        );
      }, [asPath, head, pathname])}

      <Script
        id="application/ld+json"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }}
      />
    </>
  );
};

export default GlobalHeadMeta;
