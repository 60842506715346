import { IProduct, IVariantDictionary } from '@/shared/types/product-types.interface';
import { isEmpty, isString } from 'lodash';
import type { Store, ExternalField, StorePortfolio } from '@/shared/types/store.interface';
import { Jewelry } from '../types/jewelry.interface';
import { MIN_QUANTITY_DISPLAY } from '../constants/app';

export const isStorePasswordProtected = (store: Store): boolean => !!store.isStorefrontProtected;

export const getExternalField = (store: Store, key: string) => {
  const externalField = store?.externalFields?.find((field: ExternalField) => field.name === key);
  return externalField?.value ?? null;
};

export const getStorePortfolio = (store: any): string[] => {
  if (isEmpty(store.mediaUrls)) return [];
  return store.mediaUrls.reduce((acc: string[], data: StorePortfolio) => {
    if (isString(data)) {
      return [...acc, data];
    }

    if (!isEmpty(data?.response?.url)) {
      return [...acc, data.response.url];
    }

    if (!isEmpty(data?.url)) {
      return [...acc, data.url];
    }

    return acc;
  }, []);
};

//! The location returned is currently the location based on store billing info
export const getStoreLocation = (store: Store): string => {
  if (store.addressCity && store.addressCity.length > 0) {
    return store.addressCity;
  } else if (store.addressCountry && store.addressCountry.length > 0) {
    return store.addressCountry;
  }
  return '';
};

export const getItemCoverImage = (item: IProduct) => {
  const images = getItemCoverImages(item);
  return images.length > 0 ? images[0] : '';
};

export const getItemCoverImages = (item: IProduct) => {
  const images: string[] = [];
  if (item.syncData && item.syncData.images && item.syncData.images.edges.length > 0) {
    item.syncData.images.edges.forEach(function (edge: any) {
      if (edge.node && edge.node.originalSrc) {
        images.push(edge.node.originalSrc);
      }
    });
  }
  if (item.images && item.images.length > 0) {
    images.push.apply(images, item.images);
  }
  if (item.coverUrls && item.coverUrls.length > 0) {
    images.push.apply(images, item.coverUrls);
  }
  return images;
};

export const getItemMinPrice = (item: IProduct) => {
  if (item.syncData && item.syncData.priceRange && item.syncData.priceRange.minVariantPrice) {
    return item.syncData.priceRange.minVariantPrice.amount;
  }
  return 0;
};

export const getItemMaxPrice = (item: IProduct) => {
  if (item.syncData && item.syncData.priceRange && item.syncData.priceRange.maxVariantPrice) {
    return item.syncData.priceRange.maxVariantPrice.amount;
  }
  return 0;
};

export const getItemDescription = (item: IProduct) => {
  if (item.syncData && item.syncData.description) {
    return item.syncData.description;
  }
  return '';
};

export const getItemDescriptionHtml = (item: IProduct) => {
  if (item.descriptionOverride && item.descriptionOverride.length > 0) {
    return item.descriptionOverride;
  }
  if (item.syncData && item.syncData.descriptionHtml) {
    return item.syncData.descriptionHtml;
  }
  return '';
};

export const getItemVariantOptions = (item: IProduct) => {
  if (item.syncData && item.syncData.options) {
    const variants: any[] = [];
    item.syncData.options.forEach((option: any) => {
      variants.push({
        name: option.name,
        values: option.values ?? [],
      });
    });
    return variants;
  }
  return [];
};

export const getVariantDictionaryWithBackendItem = (item: IProduct): IVariantDictionary => {
  /* The dictionary key is the variants joined by a comma.
  So for example, if the item this variant is representing is
  size 6 and 14K Gold, then the key would be "6,14K Gold". */

  const dict: any = {};
  if (item.syncData && item.syncData.variants) {
    const variants = item.syncData.variants.edges;
    variants.forEach(function (variant: any) {
      const node = variant.node;
      const info = {
        id: node.id,
        price: node.price,
        title: node.title,
        availableForSale: node.availableForSale,
        image: variant.image,
        inventory_policy: node.inventory_policy,
      };
      const options = node.selectedOptions.map((option: any) => option.value);
      dict[options.join()] = info;
    });
    return dict;
  }
  return {};
};

export const shopifyImageUrl = (url: string, size: number | string) => {
  // Remove any current image size, then add the new image size.
  return url
    .replace(
      /_(pico|icon|thumb|small|compact|medium|large|grande|original|1024x1024|2048x2048|master)+\./g,
      '.',
    )
    .replace(/(\.jpg|\.png|\.gif|\.jpeg)(?!.*\1)/g, function (match) {
      return '_' + size + match;
    });
};

export const buildQuantity = (moq: number, totalItems: number): number[] => {
  return new Array(Math.max(0, totalItems)).fill(0).map((_, i: number) => {
    return moq === 0 ? i + 1 : moq + i;
  });
};

export const getProductMoq = (item: Jewelry) => {
  return item?.meta?.wholesaleMOQ ?? MIN_QUANTITY_DISPLAY;
};
