import React from 'react';
import styles from './index.module.less';
import Image from 'next/image';

export async function getServerSideProps() {
  return {
    props: {},
  };
}

const ErrorPage = ({ statusCode }: { statusCode?: number }) => {
  return (
    <div className={styles.errorPageContainer} data-status-code={statusCode}>
      <h2 className={styles.title}>
        Sorry, we couldn&apos;t find the page you&apos;re looking for.
      </h2>
      <span>
        Please make sure you typed in the right URL. You can
        <a target="_blank" href="mailto:creators@pietrastudio.com" rel="noreferrer">
          contact
        </a>
        Pietra to discuss any problems you&apos;re experiencing.
      </span>
      <Image width={216} height={180} src="/images/404_error.png" alt="404" />
    </div>
  );
};

export default ErrorPage;
