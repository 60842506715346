import axios from '@/shared/utils/axios';

/**
 * sendSupportEmail
 * @param data
 */
export async function sendSupportEmail(data: {
  toAddresses: string[];
  bccAddresses: string[];
  subject: string;
  body: string;
}) {
  const res = await axios.post<void>('/commons/notify/send-notification-email-in-safe-mode', {
    ...data,
  });
  return res.data;
}

/**
 * GetCartItems
 * @param userId
 */
export async function getCartItems(userId: string) {
  const { data } = await axios.get(`/app/customer-cart-items?anonymousUserId=${userId}`);
  return data;
}

/**
 * DeleteCartItem
 * @param userId
 * @param itemId
 */
export async function deleteCartItem(userId: string, itemId: number) {
  const { data } = await axios.delete(
    `/app/customer-cart-items/${itemId}?anonymousUserId=${userId}`,
  );
  return data;
}

/**
 * DddItemToCart
 * @param data
 */
export async function addItemToCart(data: {
  anonymousUserId: string;
  jewelryId: number;
  variantId: string;
  quantity: number;
}) {
  const response = await axios.post('/app/customer-cart-items/action/add-to-cart', { ...data });
  return response.data;
}

export async function generateCheckout(params: {
  anonymousUserId: string;
  items: Array<
    Partial<{
      cartItemId: number;
      jewelryId: number;
      variantId: string;
      quantity: number;
    }>
  >;
}) {
  const { data } = await axios.post(`/app/customer-cart-items/action/generate-checkout`, params);
  return data;
}

export async function getBusinessSettingsDisplay(key: string) {
  const { data } = await axios.get(`/commons/business-settings/DISPLAY/${key}`);
  return data;
}
