export interface queryStoreDetailParams {
  includeMediaUrls?: boolean;
}

export interface Config {
  sendSalesToShopify: boolean;
}

export enum PlanType {
  // Default Free Plan
  TIER_1 = 'TIER_1',
  // Beginner Plan
  TIER_2 = 'TIER_2',
  // Scaling Plan
  TIER_2_5 = 'TIER_2_5',
  //  Professional Plan
  TIER_3 = 'TIER_3',
}
export interface Store {
  id: number;
  v2Modules?: string[];
  handle: string;
  title: string;
  fullName: string;
  avatarUrl: string;
  logo?: any;
  ourStory: string;
  servicesOffered: string;
  shippingAndReturnPolicy: string;
  experienceNo: number;
  mediaUrls: string[];
  instagram?: any;
  instagramFollowers?: any;
  website?: any;
  yelpOrGoogle?: any;
  addressName?: any;
  addressCountry?: any;
  addressState: string;
  addressCity: string;
  addressPostalCode?: any;
  isEnabled: boolean;
  enabledAt: Date;
  globalSort: number;
  existingEcommerceProviders?: any;
  bannerText?: any;
  youtubeAccount?: any;
  youtubeFollowers?: any;
  tiktokAccount?: any;
  tiktokFollowers?: any;
  snapchatAccount?: any;
  snapchatFollowers?: any;
  twitterAccount?: any;
  twitterFollowers?: any;
  planType: PlanType;
  hasPaidBlueprintFee?: any;
  isBlueprintTrail?: any;
  blueprintSubscriptionId?: any;
  blueprintSubscriptionStatus?: any;
  blueprintSubscriptionPeriodStartAt?: any;
  blueprintSubscriptionPeriodEndAt?: any;
  blueprintSubscriptionPausedAt?: any;
  blueprintSubscriptionBehavior?: any;
  blueprintSubscriptionResumesAt?: any;
  tags: string[];
  accountType: string;
  interestedService: string;
  businessPlanCompletedAt?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  externalFields: ExternalField[];
  shopifyAppInfo?: any;
  config: Config;
  isStorefrontProtected: boolean;
}

export interface ExternalField {
  name: string;
  value: string;
}

export interface StorePortfolio {
  uid: string;
  xhr: {
    uid: string;
  };
  name: string;
  size: number;
  type: string;
  status: string;
  percent: number;
  response: {
    url: string;
    originalUrl: string;
  };
  lastModified: number;
  originFileObj: {
    uid: string;
  };
  lastModifiedDate: string;
  url?: string;
}
