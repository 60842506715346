import { isEmpty, get } from 'lodash';
import { getItemCoverImage } from '@/shared/utils/store-helper';
import {
  OrganizationJsonLd,
  JsonLdTypes,
  ProductJsonLd,
  OfferJsonLd,
  OnlineBusinessJsonLd,
  ItemListJsonLd,
  WebApplicationJsonLd,
  PriceSpecificationJsonLd,
  FaqJsonLd,
  FaqQuestionJsonLd,
  ReviewJsonLd,
  JsonLdConditionTypes,
  JsonLdInventoryTypes,
} from '@/shared/types/seo';
import { imgix } from '@/shared/utils/utils';
import { FAQType } from '@/shared/types/subscription';
import { InventoryPolicy, IProductVariant } from '@/shared/types/product-types';
import { PIETRA_LOGO_SQUARE } from '@/shared/constants/app';

export function defaultPietraJsonLd(): OrganizationJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ORGANIZATION,
    name: 'Pietra',
    description: 'Pietra is everything you need to start and scale your business.',
    logo: PIETRA_LOGO_SQUARE,
    url: 'https://pietra.store',
  };
}

export function priceSpecification(plan: any): PriceSpecificationJsonLd {
  return {
    '@type': JsonLdTypes.PRICE_SPECIFICATION,
    priceCurrency: 'USD',
    price: plan.price.replace('$', ''),
    name: `${plan.type} - ${plan.title}`,
  };
}

export function plansJsonLd(url: string, plans: any): WebApplicationJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.WEB_APP,
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'all',
    browserRequirements: 'Requires Javascript and HTML5 support',
    url,
    offers: {
      '@type': JsonLdTypes.AGGREGATE_OFFER,
      offeredBy: defaultPietraJsonLd(),
      highPrice: '199',
      lowPrice: '39',
      offerCount: '3',
      priceCurrency: 'USD',
      priceSpecification: plans.map(priceSpecification),
    },
    creator: defaultPietraJsonLd(),
  };
}

export function registerJsonLd() {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.REGISTER,
    object: {
      '@type': JsonLdTypes.ORGANIZATION,
      name: 'Pietra',
      url: 'https://pietra.store',
    },
  };
}

export function getReviewJsonLd(author: string, name: string, body: string): ReviewJsonLd {
  return {
    '@type': JsonLdTypes.REVIEW,
    author,
    name,
    reviewBody: body,
    reviewRating: {
      '@type': JsonLdTypes.RATING,
      ratingValue: '5',
      worstRating: '1',
      bestRating: '5',
    },
  };
}

export function getProductJsonLd(product: any, url: string): ProductJsonLd {
  const brandName = product?.storeTitle ?? product?.store?.title;
  const skus = product?.skus ?? [];
  const sku = skus.length > 0 ? skus[0].sku : '';
  const name = product?.name ?? '';

  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.PRODUCT,
    name,
    description: product?.description ?? name,
    image: getItemCoverImage(product),
    sku,
    mpn: sku,
    offers: getItemOffer(product, url),
    brand: {
      '@type': JsonLdTypes.BRAND,
      name: brandName,
    },
  };
}

export function adminConfigStoreJsonLd(store: any, url: string): OnlineBusinessJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ONLINE_BUSINESS,
    name: store.title,
    description: store.description,
    image: store.imageUrl,
    url: `${url}/s/${store.storeHandle}`,
    parentOrganization: defaultPietraJsonLd(),
  };
}

export function storeJsonLd(store: any, url: string): OnlineBusinessJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ONLINE_BUSINESS,
    name: store.title,
    image: imgix(get(store, 'avatarUrl'), { h: 600, q: 100 }),
    url,
    parentOrganization: defaultPietraJsonLd(),
  };
}

export function getItemOffer(product: any, url: string): OfferJsonLd {
  if (!product?.skus) return null;

  const skus = product?.skus ?? [];
  const itemsWithInventory = skus.filter(
    (variant: IProductVariant) => variant.inventoryQuantity > 0,
  );

  const policy = !isEmpty(skus) ? skus[0].inventoryPolicy : InventoryPolicy.CONTINUE;
  const sellAfterOutOfStock =
    policy === InventoryPolicy.ALLOW || policy === InventoryPolicy.CONTINUE;
  const isAvailable =
    (isEmpty(itemsWithInventory) && sellAfterOutOfStock) || itemsWithInventory.length > 0;

  return {
    '@type': JsonLdTypes.OFFER,
    availability: isAvailable ? JsonLdInventoryTypes.OUT_OF_STOCK : JsonLdInventoryTypes.IN_STOCK,
    image: getItemCoverImage(product),
    itemCondition: JsonLdConditionTypes.NEW,
    name: `${product.name} | ${product?.skus[0].title}`,
    price: `${product?.skus[0].price}`,
    priceCurrency: 'USD',
    priceValidUntil: '2100-01-01',
    sku: product?.skus[0].sku,
    url,
  };
}

export function productsListJsonLd(products: any[], url: string, name: string): ItemListJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ITEM_LIST,
    description: 'Popular creator products on the Pietra Creator Marketplace',
    name,
    url,
    itemListElement: products.map((product: any, i: number) => {
      return {
        '@type': JsonLdTypes.LIST_ITEM,
        position: i + 1,
        item: getProductJsonLd(product, url),
      };
    }),
  };
}

export function creatorsListJsonLd(
  stores: any[],
  url: string,
  isConfigData = false,
): ItemListJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ITEM_LIST,
    url,
    name: 'Creators',
    description: 'Creators on the Pietra Creator Marketplace',
    itemListElement: stores.map((store: any, i: number) => {
      return {
        '@type': JsonLdTypes.LIST_ITEM,
        position: i + 1,
        item: isConfigData ? adminConfigStoreJsonLd(store, url) : storeJsonLd(store, url),
      };
    }),
  };
}

export function marketplaceJsonLd(url: string): OnlineBusinessJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.ONLINE_BUSINESS,
    url,
    name: 'The Largest Creator-First Marketplace | Pietra',
    description: 'Shop the largest marketplace in the world for creator-branded goods.',
    logo: PIETRA_LOGO_SQUARE,
  };
}

export function faqJsonLd(faqs: FAQType[]): FaqJsonLd {
  return {
    '@context': 'https://schema.org',
    '@type': JsonLdTypes.FAQ,
    mainEntity: faqAnswersJsonLd(faqs),
  };
}

export function faqAnswersJsonLd(faqs: FAQType[]): FaqQuestionJsonLd[] {
  return faqs.map((faq: FAQType) => {
    return {
      '@type': JsonLdTypes.QUESTION,
      name: faq.question,
      acceptedAnswer: {
        '@type': JsonLdTypes.ANSWER,
        text: faq.answer,
      },
    };
  });
}
