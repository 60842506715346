import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { addItemToCart, deleteCartItem, getCartItems } from '@/lib/service';
import storage from '@/shared/utils/storage';
import { ANONYMOUS_USER_ID } from '@/shared/constants/app';
import { groupBy } from 'lodash';

type AddCartItemParams = {
  anonymousUserId: string;
  jewelryId: number;
  variantId: string;
  quantity: number;
};
type ShopCarProviderType = {
  /**
   * total cart quantity
   */
  total?: number;
  open?: boolean;
  loading?: boolean;
  cartItems?: any[];
  /**
   * add cart callback function
   * @param params Product information added
   */
  onAddShop?: (params: AddCartItemParams) => void;
  onDeleteShopCarItem?: (itemId: number) => void;
  onOpenDrawer?: (open: boolean) => void;
  /**
   * Refresh cart data
   */
  onRefreshCartData?: () => Promise<void>;
};

const Context = createContext<ShopCarProviderType>({});
const ShoppingCartProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [state, fetchCartItemData] = useAsyncFn(() => getCartItems(storage.get(ANONYMOUS_USER_ID)));
  const handleAddShopCarItem = (params: AddCartItemParams) => {
    addItemToCart(params).then(() => {
      fetchCartItemData();
      setOpen(true);
    });
  };

  const handleDeleteShopCarItem = (itemId: number) => {
    deleteCartItem(storage.get(ANONYMOUS_USER_ID), itemId).then(() => {
      fetchCartItemData();
    });
  };

  useEffect(() => {
    fetchCartItemData();
  }, [fetchCartItemData]);

  const handleOpenDrawer = (open: boolean) => {
    setOpen(open);
  };

  const handleRefreshCartData = async () => {
    return fetchCartItemData();
  };

  const formatCartData = (data: API.CartItem[]) => {
    const groupsByStoreAndPrintful = groupBy(
      data,
      (d) => `${d.storeId}-${d.jewelry.isFromPrintful}`,
    );
    const result = [];

    for (const key of Object.keys(groupsByStoreAndPrintful)) {
      let items = groupsByStoreAndPrintful[key];
      result.push({
        ...items[0].store,
        isPrintfulOrder: items[0].jewelry.isFromPrintful,
        items,
      });
    }

    return result;
  };

  const exposed = {
    open,
    loading: state.loading || false,
    cartItems: formatCartData(state.value?.list || []),
    total: state.value?.size || 0,
    onAddShop: handleAddShopCarItem,
    onDeleteShopCarItem: handleDeleteShopCarItem,
    onOpenDrawer: handleOpenDrawer,
    onRefreshCartData: handleRefreshCartData,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useShoppingCartProvider = () => useContext(Context);

export default ShoppingCartProvider;
