import axios from 'axios';
import getConfig from 'next/config';
import { message } from 'antd';

const { publicRuntimeConfig } = getConfig();
const instance = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_HOST,
  timeout: 10000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof window === 'object' && error?.code !== 'ERR_BAD_REQUEST') {
      message.destroy();
      message.error('Sorry, something went wrong. Please try again.');
    }
    return Promise.reject(error);
  },
);
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
