import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { createShopAllQueryString } from '@/shared/utils/marketplace';
import { useWindowSize } from 'react-use';
import { isMobileByUserAgent } from '@/shared/utils/utils';
import useBusinessSettingsDisplay from '@/lib/hooks/common/useBusinessSettingsDisplay';

type ProviderType = {
  isMobile?: boolean;
  isShopPage?: boolean;
  menus?: Array<any>;
  screenSize?: {
    width: number;
    height: number;
  };
  userAgent?: string;
  unlockedStores?: number[];
  setUnlockedStores?: (unlockedStores: number[]) => void;
};

const Context = createContext<ProviderType>({});
const Provider = (props: any) => {
  const { value, children } = props;
  const { width, height } = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileByUserAgent(value.userAgent) || false);
  const [unlockedStores, setUnlockedStores] = useState<number[]>([]);
  const [screenSize, setScreenSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const router = useRouter();
  const { value: menuRes } = useBusinessSettingsDisplay('CATEGORY_NAV_DROPDOWN_ITEMS');

  useEffect(() => {
    setIsMobile(isMobileByUserAgent(value.userAgent) || width < 780);
    setScreenSize({ width, height });
  }, [height, value.userAgent, width]);

  const formattedCategoryNavDropdownItems = useCallback(() => {
    return (menuRes?.value || []).map((term: any) => {
      return {
        text: term.text,
        path: createShopAllQueryString(term.categories.join(','), term.storeIds),
      };
    });
  }, [menuRes?.value]);

  const exposed = {
    menus: formattedCategoryNavDropdownItems(),
    isShopPage: router.pathname.startsWith('/shop'),
    isMobile,
    screenSize,
    unlockedStores,
    setUnlockedStores,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useConfigProvider = () => useContext(Context);

export default Provider;
