export interface MetaDataProperty {
  vmid?: string;
  once?: boolean;
  skip?: boolean;
  body?: boolean;
  pbody?: boolean;
  [key: string]: any;
}

export interface MetaPropertyCharset extends MetaDataProperty {
  charset: string;
}

export interface MetaPropertyEquiv extends MetaDataProperty {
  httpEquiv: string;
  content: string;
  template?: (chunk: string) => string;
}

export interface MetaPropertyName extends MetaDataProperty {
  name: string;
  content: string;
  template?: (chunk: string) => string;
}

export interface MetaPropertyMicrodata extends MetaDataProperty {
  itemprop: string;
  content: string;
  template?: (chunk: string) => string;
}

// non-w3c interface
export interface MetaPropertyProperty extends MetaDataProperty {
  property: string;
  content: string;
  template?: (chunk: string) => string;
}

export interface LinkPropertyBase extends MetaDataProperty {
  rel: string;
  crossOrigin?: string | null;
  media?: string;
  nonce?: string;
  referrerPolicy?: string;
  rev?: string;
  type?: string;
}

export interface LinkPropertyHref extends LinkPropertyBase {
  href?: string;
  hreflang?: string;
  callback?: void;
}

export interface LinkPropertyHrefCallback extends LinkPropertyBase {
  vmid: string;
  callback: () => void;
  href?: string;
  hreflang?: string;
}

export interface StyleProperty extends MetaDataProperty {
  cssText: string;
  callback?: () => void;
  media?: string;
  nonce?: string;
  type?: string;
}

export interface ScriptPropertyBase extends MetaDataProperty {
  type?: string;
  charset?: string;
  async?: boolean;
  defer?: boolean;
  crossOrigin?: string;
  nonce?: string;
}

export interface ScriptPropertyText extends ScriptPropertyBase {
  innerHTML: string;
}

export interface ScriptPropertySrc extends ScriptPropertyBase {
  src: string;
  callback?: void;
}

export interface ScriptPropertySrcCallback extends ScriptPropertyBase {
  vmid: string;
  callback: () => void;
}

type JsonVal = string | number | boolean | JsonObj | JsonObj[] | null;

interface JsonObj {
  [key: string]: JsonVal | JsonVal[];
}

export interface ScriptPropertyJson extends ScriptPropertyBase {
  json: JsonObj;
}

export interface AttributeProperty {
  [key: string]: string | string[];
}

export interface NoScriptProperty extends MetaDataProperty {
  innerHTML: string;
}

export interface MetaInfoOutput extends MetaInfo {
  titleChunk?: string;
}

export interface MetaInfo {
  title?: string;
  titleTemplate?: string | ((titleChunk: string) => string);

  htmlAttrs?: AttributeProperty;
  headAttrs?: AttributeProperty;
  bodyAttrs?: AttributeProperty;

  base?: {
    target: string;
    href: string;
  };

  meta?: (
    | MetaPropertyCharset
    | MetaPropertyEquiv
    | MetaPropertyName
    | MetaPropertyMicrodata
    | MetaPropertyProperty
  )[];
  link?: (LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback)[];
  style?: StyleProperty[];
  script?: (
    | ScriptPropertyText
    | ScriptPropertySrc
    | ScriptPropertySrcCallback
    | ScriptPropertyJson
  )[];
  noscript?: NoScriptProperty[];

  __dangerouslyDisableSanitizers?: string[];
  __dangerouslyDisableSanitizersByTagID?: {
    [key: string]: string[];
  };

  changed?: <T extends MetaInfoOutput>(
    newInfo: T,
    addedTags: HTMLElement[],
    removedTags: HTMLElement[],
  ) => void;
  afterNavigation?: <T extends MetaInfoOutput>(newInfo: T) => void;
}

export enum HeaderTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export interface pageMetadataArg {
  description?: string;
  image?: string;
  keywords?: string[];
  url?: string;
  script?: { src: string }[];
  siteName?: string;
  title?: string;
}

export interface SeoHeader {
  tag: HeaderTag;
  text: string;
}

export interface jsonLdParam {
  plans?: any;
  product?: any;
  products?: any;
  store?: any;
  stores?: any;
  url?: string;
}

export enum SEO_META_PAGES {
  BRAND_KIT = 'BRAND_KIT',
  CATALOG_ITEMS = 'CATALOG_ITEMS',
  EXISTING_BRANDS = 'EXISTING_BRANDS',
  NEW_BRANDS = 'NEW_BRANDS  ',
  ERROR = 'ERROR',
  FULFILLMENT_SERVICES = 'FULFILLMENT_SERVICES',
  HOME = 'HOME',
  HOW_PIETRA_WORKS = 'HOW_PIETRA_WORKS',
  MARKETPLACE = 'MARKETPLACE',
  MARKETPLACE_CREATOR_SHOP = 'MARKETPLACE_CREATOR_SHOP',
  MARKETPLACE_DESIGNERS = 'MARKETPLACE_DESIGNERS',
  MARKETPLACE_PDP = 'MARKETPLACE_PDP',
  MARKETPLACE_SHOP_ALL = 'MARKETPLACE_SHOP_ALL',
  PIETRA_PARTNERS = 'PIETRA_PARTNERS',
  PRICING = 'PRICING',
  SELL_ON_PIETRA = 'SELL_ON_PIETRA',
  SETUP_COMMERCE = 'SETUP_COMMERCE',
  AUTOMATED_RETURNS = 'AUTOMATED_RETURNS',
}

export interface SeoMeta {
  [SEO_META_PAGES.BRAND_KIT]: {
    headers: (headerData: SeoHeader[]) => SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.CATALOG_ITEMS]: {
    headers: (headerData: SeoHeader[]) => SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.EXISTING_BRANDS]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.NEW_BRANDS]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.ERROR]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.HOME]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: () => void;
  };
  [SEO_META_PAGES.HOW_PIETRA_WORKS]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.MARKETPLACE]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.MARKETPLACE_CREATOR_SHOP]: {
    headers: (headerData: SeoHeader[]) => SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.AUTOMATED_RETURNS]: {
    headers: (headerData: SeoHeader[]) => SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.MARKETPLACE_DESIGNERS]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: ({ stores, url }: jsonLdParam) => void;
  };
  [SEO_META_PAGES.MARKETPLACE_PDP]: {
    headers: (headerData: SeoHeader[]) => SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.MARKETPLACE_SHOP_ALL]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.PIETRA_PARTNERS]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: () => void;
  };
  [SEO_META_PAGES.PRICING]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.SELL_ON_PIETRA]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.SETUP_COMMERCE]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
  [SEO_META_PAGES.FULFILLMENT_SERVICES]: {
    headers: SeoHeader[];
    head: (params: pageMetadataArg) => MetaInfo | (() => MetaInfo);
    jsonld: (params: jsonLdParam) => void;
  };
}

export enum JsonLdTypes {
  ANSWER = 'Answer',
  AGGREGATE_OFFER = 'AggregateOffer',
  AGGREGATE_RATING = 'AggregateRating',
  BRAND = 'Brand',
  FAQ = 'FAQPage',
  ITEM_LIST = 'ItemList',
  LIST_ITEM = 'ListItem',
  OFFER = 'Offer',
  ONLINE_BUSINESS = 'OnlineBusiness',
  ORGANIZATION = 'Organization',
  PRICE_SPECIFICATION = 'UnitPriceSpecification',
  PRODUCT = 'Product',
  QUESTION = 'Question',
  RATING = 'Rating',
  REGISTER = 'RegisterAction',
  REVIEW = 'Review',
  WEB_APP = 'WebApplication',
}

export enum JsonLdConditionTypes {
  NEW = 'https://schema.org/NewCondition',
}

export enum JsonLdInventoryTypes {
  IN_STOCK = 'https://schema.org/InStock',
  OUT_OF_STOCK = 'https://schema.org/OutOfStock',
}

export interface BaseJsonLd {
  '@context'?: 'https://schema.org';
  name?: string;
  description?: string;
}

export interface OrganizationJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.ORGANIZATION;
  url?: string;
  logo?: string;
}

export interface PriceSpecificationJsonLd {
  '@type': JsonLdTypes.PRICE_SPECIFICATION;
  price: string;
  priceCurrency: 'USD';
  name: string;
}

export interface WebApplicationJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.WEB_APP;
  applicationCategory: 'BusinessApplication';
  operatingSystem: 'all';
  browserRequirements: 'Requires Javascript and HTML5 support';
  url: string;
  offers: {
    '@type': JsonLdTypes.AGGREGATE_OFFER;
    offeredBy: OrganizationJsonLd;
    highPrice: string;
    lowPrice: string;
    offerCount: string;
    priceCurrency: 'USD';
    priceSpecification: PriceSpecificationJsonLd[];
  };
  creator: OrganizationJsonLd;
}
export interface OfferJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.OFFER;
  itemCondition: JsonLdConditionTypes.NEW;
  availability: JsonLdInventoryTypes;
  url: string;
  sku: number;
  price: string;
  image: string;
  priceCurrency: string;
  priceValidUntil: string;
}

export interface BrandJsonLd {
  '@type': JsonLdTypes.BRAND;
  name: string;
}

export interface ReviewRatingJsonLd {
  '@type': JsonLdTypes.RATING;
  ratingValue: string;
  worstRating: string;
  bestRating: string;
}

export interface ReviewJsonLd {
  '@type': JsonLdTypes.REVIEW;
  author: string;
  name: string;
  reviewBody: string;
  reviewRating: ReviewRatingJsonLd;
}

export interface AggregateRatingJsonLd {
  '@type': JsonLdTypes.AGGREGATE_RATING;
  ratingValue: string;
  reviewCount: string;
}

export interface ProductJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.PRODUCT;
  image: string;
  sku: string;
  isbn?: string;
  gtin?: string;
  mpn?: string;
  offers: OfferJsonLd | OfferJsonLd[];
  brand: BrandJsonLd;
  review?: ReviewJsonLd;
  aggregateRating?: AggregateRatingJsonLd;
}

export interface OnlineBusinessJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.ONLINE_BUSINESS;
  image?: string;
  url?: string;
  logo?: string;
  parentOrganization?: OrganizationJsonLd;
}

export interface ListItemJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.LIST_ITEM;
  position: number;
  item: ProductJsonLd | OnlineBusinessJsonLd | OnlineBusinessJsonLd;
}

export interface ItemListJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.ITEM_LIST;
  url: string;
  itemListElement: ListItemJsonLd[];
}

export interface FaqAcceptedAnswerJsonLd {
  '@type': JsonLdTypes.ANSWER;
  text: string;
}

export interface FaqQuestionJsonLd {
  '@type': JsonLdTypes.QUESTION;
  name: string;
  acceptedAnswer: FaqAcceptedAnswerJsonLd;
}
export interface FaqJsonLd extends BaseJsonLd {
  '@type': JsonLdTypes.FAQ;
  mainEntity: FaqQuestionJsonLd[];
}
